export const sectionList = [
  'headerWithInlineImage', // contains header,subheader, paragraph, link & an inline image.
  'headerWithImage', // contains header,subheader,paragraph,list,links,image with width 100%.
  'headerWithParagraph', //contains header,text,link,list,image.
  'headerWithParagraphImageRight', //contains header,text,link,list,image on right.
  'faqs', // contains FAQs section
  'headerWithButtonImageRight', //contains header,text,Button,list,image on right.
  'imageListColumn', //row column structure for image list.
  'imageWithPath', //Link Associated with Image component.
  'imageWithAsset', //Asset associated with Image component.
  'stickyButton', //sticky button on right of the page.
  'linkWithImageLeft', //images, links
  'contentListColumn', //row,column structure for a contentList.
  'marketoForm'
];

export const FormTypes = [
  'marketoForm',
  'marketoFormClear',
  'slpMarketoForm',
  'slpWelcomeMarketoForm',
  'slpMarketoFormCard',
];
