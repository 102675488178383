import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Link } from 'gatsby';

export const Anchor = styled.a`
  text-decoration: none;
`;
export const NoAnchorDiv = styled.div``;
export const AnchorInternal = styled(Link)`
  text-decoration: none;
`;

export const BlockTitle = styled.h2`
  background: #1fb25a;
  padding: 16px 30px 14px 30px;
  font-family: 'Gotham-Medium', arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: normal !important;
  letter-spacing: 0.02em;
  margin: 0 !important;
  border-bottom: none !important;
`;

export const BlockArea = styled.div`
  padding: 26px 26px 10px 26px;
  background: #fff;
  border-top: none !important;
`;

export const BlockContainer = styled.div`
  @media print {
    display: none;
  }
  display: block;
  margin-bottom: 40px;
  ${BlockArea} {
    border: 4px solid #e5e5e5;
  }
  :hover {
    ${BlockArea} {
      border: 4px solid #33a23c;
    }
  }
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    ${BlockTitle} {
      border: 4px solid #e5e5e5;
    }
    :hover {
      ${BlockTitle} {
        border: 4px solid #33a23c;
      }
    }
  }
`;

export const CardCol = styled(Col)`
  justify-content: start;
  overflow: hidden;
`;
export const Heading3 = styled.h3`
  font-size: 26px;
  line-height: 30px;
  font-family: 'Gotham-Light', arial, sans-serif;
  color: #1fb25a;
  letter-spacing: 0;
  margin-bottom: 20px;
  a {
    font-size: 26px !important;
  }
`;

export const Paragraph = styled.p`
  font-size: 15px;
  font-family: 'Gotham-Book', arial, sans-serif;
  padding: 0;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #666;
  b {
    font-weight: bold;
    font-family: 'Gotham-Bold', arial, sans-serif;
  }
`;

export const Img = styled.img`
  width: auto;
  height: auto;
  vertical-align: top;
  float: right;
  margin-left: 8px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: ${(props) => (props?.isRetail ? '#2c64a8' : '#1fb25a')};
  font-size: 13px;
  line-height: 22px;
  :hover {
    text-decoration: none;
    color: ${(props) => (props?.isRetail ? '#2c64a8' : '#0397d6')};
  }
`;

export const ImageStyle = styled.img`
  float: right;
  width: 50px;
  height: auto;
  margin-top: 10px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 30px 0;
  clear: both;
  opacity: 1;
`;
