import styled from 'styled-components';
import ArrowBlue from '../../images/arrow-blue.png';
import { Link } from 'gatsby';

export const RightMenuPanelContainer = styled.div`
  margin-bottom: 30px;
  @media print {
    display: none;
  }
`;

export const ListContainer = styled.ul`
  margin-left: 0px;
  padding-left: 0px;
`;

export const LiContainer = styled.li`
  z-index: 2;
  margin: 0px;
  padding: 0px;
  list-style: none;
  background: none;
  font-size: 14px;
  font-family: 'Gotham-Medium', arial, sans-serif;
  line-height: 18px;
  letter-spacing: 0.04em;
`;

export const LinkStyleInternal = styled(Link)`
  display: block;
  padding: 12px 0px 8px 40px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #083a81;
  background-size: 7px auto;
  text-decoration: none;
  text-align: left;
  transition: background-position 0.15s ease-out;
  cursor: pointer;
  :hover {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #0397d6;
  }
  &.active {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #0397d6;
  }
`;

export const LinkStyle = styled.a`
  display: block;
  padding: 12px 0px 8px 40px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #083a81;
  background-size: 7px auto;
  text-decoration: none;
  text-align: left;
  transition: background-position 0.15s ease-out;
  cursor: pointer;
  :hover {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #0397d6;
  }
  &.active {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #0397d6;
  }
`;

export const ActiveLink = {
  background: `url(${ArrowBlue}) no-repeat 1px 9px`,
  backgroundSize: '7px',
  backgroundPosition: '20px 13px',
  color: '#0397d6',
};

export const ActiveDrop = {
  '&:hover': { 
    
  },
};

export const SelectStyle = styled.select`

  padding: 12px 0px 8px 40px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #083a81;
  background-size: 7px auto;
  text-decoration: none;
  text-align: left;
  transition: background-position 0.15s ease-out;
  cursor: pointer;
  :hover {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #0397d6;
  }
  &.active {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #0397d6;
  }
`;

export const DropdownListStyle = styled.h6`
  padding: 12px 0px 0px 40px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #083a81;
  background-size: 7px auto;
  text-decoration: none;
  text-align: left;
  transition: background-position 0.15s ease-out;
  cursor: pointer;
  overflow: hidden;
  height: 28px;
  :hover {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #0397d6;
    height: 70px !important;
    -webkit-transition: height 0.3s ease;
    transition: height 0.3s ease;
  }
  &.active {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 20px 13px;
    color: #00ff00;
    height: 1000px;
    border-color: red;
  }
`;

export const SubListStyle = styled.p`
  color: #083a81;
  text-transform: uppercase;
  line-height: 20px;
  margin: 0;
  padding-top: 4px;
  padding-left: 5%;
  :hover {
    background: url(${ArrowBlue}) no-repeat 1px 9px;
    background-size: 7px;
    background-position: 0px 8px;
    color: #0397d6;
  }
`;

export const AnchorStyle = styled.a`
  line-height: 15px;
  text-decoration: none;
  font-size: 12px;
  color: #083a81;
  :hover {
    color: #0397d6;
  }
`;