import React, {useState} from 'react';
import {
  RightMenuPanelContainer,
  ListContainer,
  LiContainer,
  LinkStyle,
  LinkStyleInternal,
  ActiveLink,
  DropdownListStyle,
  AnchorStyle,
  SubListStyle,
} from './pageLeftMenuStyle';

import useMenus from '../../hooks/useMenus';
import isExternalUrl from '../../utils';
import { isExternalUrlhref, addTrailingSlash } from '../../utils';
import { Match } from '@reach/router';

const PageLeftMenu = ({ path }) => {

  const [visible, setVisible] = useState(false);

  let result = path.split('/').filter((n) => n);
  const menuItem = useMenus().navigations.filter((navigation) => {
    return navigation.type === result[0];
  })[0];

  const validateRoute = (route) => {
    const menu = menuItem.subNavigations;
    for (let i = 0; i < menu?.length; i++) {
      for (let j = 0; j < menu[i].subMenusWithPaths?.length; j++) {
        if (menu[i].subMenusWithPaths[j].path === route) {
          return true;
        }
      }
    }
    return false;
  };

  const handleDropdown = () => {
    setVisible(!visible);
  }

  return (
    <RightMenuPanelContainer>
      <ListContainer>
        {menuItem?.subNavigations?.map((subNavigation, index) => {
          return (
            <LiContainer key={index}>
              {isExternalUrl(subNavigation.textWithPath.path) ? (
                <LinkStyle
                  getProps={({ isCurrent }) => {
                    return isCurrent ? { style: ActiveLink } : {};
                  }}
                  key={index}
                  href={
                    isExternalUrlhref(!subNavigation.textWithPath.path)
                      ? subNavigation.textWithPath.path + '#title'
                      : process.env.GATSBY_DOMAIN_URL +
                        subNavigation.textWithPath.path
                  }
                  target="_blank"
                >
                  {subNavigation?.textWithPath?.text}
                </LinkStyle>
              ) : subNavigation.type === 'products/services' 
                ? (   
                  <DropdownListStyle
                    onClick={() => handleDropdown()}
                  >Products/Services
                    <SubListStyle>
                        <AnchorStyle
                          href={process.env.GATSBY_DOMAIN_URL+subNavigation.textWithPath?.path}
                        >Techron
                        </AnchorStyle>
                      </SubListStyle>
                    <SubListStyle>
                      <AnchorStyle
                        href={process.env.GATSBY_DOMAIN_URL+subNavigation.textWithPath?.path+'carwash'}
                      >Car Wash
                      </AnchorStyle>
                    </SubListStyle>
                  </DropdownListStyle>
                )
              : (
                <Match path={subNavigation.textWithPath.path}>
                  {({ match }) => (
                    <LinkStyleInternal
                      style={
                        match
                          ? validateRoute(subNavigation.textWithPath.path)
                            ? {}
                            : ActiveLink
                          : {}
                      }
                      to={
                        isExternalUrlhref(subNavigation.textWithPath.path)
                          ? subNavigation.textWithPath.path
                          : addTrailingSlash(
                              process.env.GATSBY_DOMAIN_URL +
                                subNavigation.textWithPath.path +
                                '#title'
                            )
                      }
                    >
                      {subNavigation?.textWithPath?.text}
                    </LinkStyleInternal>
                  )}
                </Match>
              )}
            </LiContainer>
          );
        })}
      </ListContainer>
    </RightMenuPanelContainer>
  );
};

export default PageLeftMenu;
