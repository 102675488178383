import * as React from 'react';
import { isExternalUrlhref, addTrailingSlash } from '../../utils';
import isExternalUrl from '../../utils';
import * as Styled from './blockLeftPanelStyles';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../hooks/useContentfulImages';

const BlockLeftPanel = ({ path }) => {
  let result = path.split('/').filter((n) => n);
  const commonCard = useStaticQuery(graphql`
    query commonCardQuery {
      allContentfulChevron(
        filter: { identifier: { type: { eq: "commonCardsSection" } } }
      ) {
        edges {
          node {
            title
            name
            sections {
              ... on ContentfulListOfImageWithPath {
                id
                title
                type
                listOfImagesWithPath {
                  path
                  title
                  type
                  richText {
                    raw
                  }
                  image {
                    title
                    file {
                      fileName
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const commonCardSections =
    commonCard?.allContentfulChevron?.edges[0]?.node?.sections;
  const commonCardData = commonCardSections?.filter(
    (section) => section.type === result[0]
  )[0];
  const commonCardsList = commonCardData?.listOfImagesWithPath;

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.Paragraph>{children}</Styled.Paragraph>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.Heading3>{children}</Styled.Heading3>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          isRetail={children[0][1] === 'Retail Locations' ? true : false}
          href={
            isExternalUrlhref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      {commonCardsList?.map((cards, index) =>
        cards?.path !== '!' ? (
          <Styled.Anchor
            href={
              isExternalUrlhref(cards?.path)
                ? cards?.path
                : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + cards?.path)
            }
            target={isExternalUrl(cards?.path) ? '_blank' : '_self'}
            key={index}
          >
            <Styled.BlockContainer>
              <Styled.BlockTitle>{cards?.title}</Styled.BlockTitle>
              <Styled.BlockArea>
                <Styled.CardCol>
                  {cards?.image && <Styled.Img src={cards?.image?.file?.url} />}
                  {cards?.richText &&
                    documentToReactComponents(
                      JSON.parse(cards?.richText?.raw),
                      optionsMainStyle
                    )}
                </Styled.CardCol>
              </Styled.BlockArea>
            </Styled.BlockContainer>
          </Styled.Anchor>
        ) : (
          <Styled.NoAnchorDiv key={index}>
            <Styled.BlockContainer>
              <Styled.BlockTitle>{cards?.title}</Styled.BlockTitle>
              <Styled.BlockArea>
                <Styled.CardCol>
                  {cards?.image && <Styled.Img src={cards?.image?.file?.url} />}
                  {cards?.richText &&
                    documentToReactComponents(
                      JSON.parse(cards?.richText?.raw),
                      optionsMainStyle
                    )}
                </Styled.CardCol>
              </Styled.BlockArea>
            </Styled.BlockContainer>
          </Styled.NoAnchorDiv>
        )
      )}
    </>
  );
};

export default BlockLeftPanel;
