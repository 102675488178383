import React from 'react';
import Hamburger from '../../images/icon-hamburger.png';
import * as Styled from '../../templates/commonPageTemplate/commonPageTemplateStyles';
import BlockLeftPanel from '../blockLeftPanel/blockLeftPanel';
import PageLeftMenu from '../pageLeftMenu/pageLeftMenu';

function TabletRightMenu({ location }) {
  const [showMenu, setShowMenu] = React.useState(false);
  const handleMenu = (event) => {
    setShowMenu(!event);
  };
  return (
    <React.Fragment>
      <Styled.Menubar
        onClick={() => {
          handleMenu(showMenu);
        }}
      >
        <Styled.ArrowImage src={Hamburger} alt="Hamburger icon" />
        Menu & Contact Info
      </Styled.Menubar>
      {showMenu && (
        <Styled.MenubarBody>
          <PageLeftMenu path={location?.pathname} />
          <BlockLeftPanel path={location?.pathname} />
        </Styled.MenubarBody>
      )}
    </React.Fragment>
  );
}

export default TabletRightMenu;
