import { graphql } from 'gatsby';
import React from 'react';
import { sectionList } from '../../constants/queryFragments';
import GlobalStyles from '../../styles/globalStyles';
import loadable from '@loadable/component';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import * as Styled from './commonPageTemplateStyles';
import Footer from '../../components/footer/footer';
import { Helmet } from 'react-helmet';
import BottomArrow from '../../components/bottomArrow/bottomArrow';
import TopBanner from '../../common/topBanner/topBanner';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import PageLeftMenu from '../../common/pageLeftMenu/pageLeftMenu';
import BlockLeftPanel from '../../common/blockLeftPanel/blockLeftPanel';
import { addTrailingSlash } from '../../utils';
import LogoOG from '../../images/logo-og.jpg';
import TabletRightMenu from '../../common/tabletRightMenu/tabletRightMenu';

const CommonPageTemplate = ({ data, location }) => {
  React.useEffect(() => {
    if (!location?.hash) {
      window.scrollTo(0, 0);
    }
  }, []);
  const path = location?.pathname?.split('/')[1];

  const sectionsArray = data?.allContentfulChevron?.edges[0]?.node?.sections;

  return (
    <React.Fragment>
      <GlobalStyles />
      <Helmet>
        <title>
          {data?.allContentfulChevron?.edges[0]?.node?.title ??
            'Parkland | Chevron'}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        {data?.allContentfulChevron?.edges[0]?.node?.metadata && (
          <meta
            name="description"
            content={data?.allContentfulChevron?.edges[0]?.node?.metadata}
          ></meta>
        )}
        {data?.allContentfulChevron?.edges[0]?.node?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
        <meta
          name="keywords"
          content="Parkland, Chevron, Fuel, Canada, Retail, Commercial, Gas Station, Cardlock"
        ></meta>
        <meta name="generator" content="concrete5 - 5.6.3.2"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Parkland | Chevron"></meta>
        <meta
          property="og:description"
          content="Retail and commercial fuel services"
        ></meta>
        <meta property="og:image" content={LogoOG}></meta>
        <meta
          property="og:url"
          content={addTrailingSlash(process.env.GATSBY_DOMAIN_URL)}
        ></meta>
        <meta name="twitter:title" content="Parkland | Chevron"></meta>
        <meta
          name="twitter:description"
          content="Retail and commercial fuel services"
        ></meta>
        <meta name="twitter:image" content={LogoOG}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <BottomArrow />
      <DefaultHeaderMenu />
      <BootstrapNavbar activeLink={path} />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock path={location.pathname} />
      <Styled.CommonPageContainer>
        <Styled.InnerContainer>
          <Styled.BodyLeft>
            <PageLeftMenu path={location.pathname} />
            <BlockLeftPanel path={location.pathname} />
          </Styled.BodyLeft>
          <Styled.BodyRight>
            <TabletRightMenu location={location} />
            {sectionsArray &&
              sectionsArray?.map((section, index) => {
                if (section?.type && sectionList?.includes(section?.type)) {
                  const SectionComponent = loadable(() =>
                    import(`../../components/${section?.type}/${section?.type}`)
                  );
                  return (
                    <SectionComponent
                      key={index}
                      sectionData={section}
                      location={location}
                    />
                  );
                }
              })}
            <Styled.CardStyle>
              <BlockLeftPanel path={location.pathname} />
            </Styled.CardStyle>
          </Styled.BodyRight>
        </Styled.InnerContainer>
      </Styled.CommonPageContainer>
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </React.Fragment>
  );
};

export default CommonPageTemplate;

export const pageQuery = graphql`
  query allCommonPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulFaqs: Boolean!
    $contentfulListImagesWithPath: Boolean!
    $contentfulImageWithPath: Boolean!
    $contentfulContentList: Boolean!
  ) {
    allContentfulChevron(
      filter: {
        identifier: { type: { eq: $pageIdentifier } }
        node_locale: { eq: "en-US" }
      }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          title
          metadata
          hasIndex
          node_locale
          sections {
            ...PageContent @include(if: $contentfulContent)
            ...FAQContent @include(if: $contentfulFaqs)
            ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
            ...ImageWithPath @include(if: $contentfulImageWithPath)
            ...ContentList @include(if: $contentfulContentList)
          }
        }
      }
    }
  }
`;
